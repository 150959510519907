"use client";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import tinyColor from "tinycolor2";
import { colors, includeSpacing, rem } from "@boxt/design-system";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${rem(10)};
  ${includeSpacing({ mh: 1, mv: 1 })}
`;

export const CodeContainer = styled.div`
  position: relative;
  display: flex;
  border: 1px dashed ${colors.jade.normal};
  border-radius: 0;
  justify-content: center;
  align-items: center;
`;

export const CrossWrapper = styled.button`
  margin: ${rem(5)};
  height: ${rem(32)};
  width: ${rem(32)};
  position: absolute;
  border-radius: ${rem(20)};
  top: 0;
  right: 0;
  border: none;
  background: ${colors.white};
  cursor: pointer;
  &:hover {
    background-color: ${tinyColor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${includeSpacing({ mv: 1 })}
`;

export const StyledToastContainer = styled(ToastContainer)`
  @media only screen and (max-width: 480px) {
    margin-top: ${rem(16)};
    width: 100%;
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};

    .Toastify__toast {
      border-radius: ${rem(14)};
    }
  }
`;
