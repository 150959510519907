import type { ReactElement } from "react";
import { noop } from "lodash-es";
import { PopUp } from "@boxt/design-system";

import type { ButtonThemeType, ButtonType } from "./ButtonTemplate";
import ButtonTemplate from "./ButtonTemplate";
import type { Template as CTemplate } from "./ContentTemplate";
import ContentTemplate from "./ContentTemplate";

type Props = {
  nameSpace: { path: string; key: string };
  onDismiss?: () => void;
  contentTemplate?: CTemplate;
  children?: ReactElement;
  buttonType?: ButtonType;
  homePath?: string;
  ariaLabel: string;
  contentSize?: "small" | "medium" | "large" | "xlarge";
  buttonTheme?: ButtonThemeType;
};

const PopUpError = ({
  buttonTheme,
  nameSpace,
  onDismiss = noop,
  children,
  contentTemplate,
  buttonType,
  homePath,
  ariaLabel,
  contentSize = "small",
}: Props) => {
  if (children) {
    return (
      <PopUp ariaLabel={{ "aria-label": ariaLabel }} contentSize={contentSize} onDismiss={onDismiss}>
        {children}
      </PopUp>
    );
  }
  return (
    <PopUp ariaLabel={{ "aria-label": ariaLabel }} contentSize={contentSize} onDismiss={onDismiss}>
      <>
        {contentTemplate && <ContentTemplate nameSpace={nameSpace} template={contentTemplate} />}
        {buttonType && (
          <ButtonTemplate
            buttonTheme={buttonTheme}
            homePath={homePath}
            nameSpace={nameSpace}
            onClick={onDismiss}
            type={buttonType}
          />
        )}
      </>
    </PopUp>
  );
};

export default PopUpError;
