import { useRouter } from "next/router";
import { useFsFlag } from "@flagship.io/react-sdk";
import useSWRImmutable from "swr/immutable";

import { Products as ProductsType } from "@Collections/products";
import { fetcher } from "@DataAccess/local";
import { errors } from "@Components/ErrorHandler";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

const usePromoCode = (
  promoCodeCookie: string | null = null,
  productType: ProductsType | undefined = ProductsType.Boiler,
  screenerVariant?: ScreenerVariant | undefined,
) => {
  const { query, push, pathname } = useRouter();
  const promoCode = promoCodeCookie ? promoCodeCookie : (query.promo as string);
  const isBOXTLifePromoCodeFlagEnabled = useFsFlag("promo-codes-on-BOXT-Life", false).getValue();
  const isBOXTLife = screenerVariant === ScreenerVariant.BoilerSubscription;
  const { data: promoCodeData, error: promoCodeError } = useSWRImmutable(
    promoCode ? `/customers/promo-codes/${promoCode}` : null,
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );

  const removePromoCodeQueryParam = () => {
    delete query.promo;

    push({ pathname, query }, undefined, { shallow: true });
  };

  const getPromoCodeError = () => {
    if (promoCodeError) {
      return errors.invalidPromo;
    }

    if (promoCodeData && promoCodeData?.error !== null) {
      return promoCodeData?.error;
    }

    return null;
  };

  const isPromoCodeAllowedForProductType = promoCodeData?.promoCode?.productCategories?.some(
    ({ value }) => value === productType,
  );

  return {
    hasActivePromoCode:
      Boolean(promoCodeData) &&
      promoCodeData?.error === null &&
      !promoCodeError &&
      (isBOXTLife ? isBOXTLifePromoCodeFlagEnabled : true),
    isPromoCodeAllowedForProductType: Boolean(isPromoCodeAllowedForProductType),
    promoCodeData: promoCodeData?.error === null ? promoCodeData : null,
    promoCodeError: getPromoCodeError(),
    removePromoCodeQueryParam,
  };
};

export default usePromoCode;
