import useSWR from "swr";

import type { Products } from "@Collections/products";
import { fetcher } from "@DataAccess/local";
import type { ScreenerData } from "@Pages/api/_next/screeners/types";

type Props = {
  productType: Products;
  screeningId: string;
};

const useScreening = ({ productType, screeningId }: Props) => {
  const { data: screeningData, error } = useSWR<ScreenerData>(`/screenings/${screeningId}`, (url: string) =>
    fetcher(url, { id: screeningId, productType }),
  );

  return {
    isScreeningDataLoading: (!screeningData && !error) || Boolean(error),
    screeningData,
  };
};

export default useScreening;
