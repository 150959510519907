import type { RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { useFsFlag } from "@flagship.io/react-sdk";
import { ReactLenis } from "@studio-freight/react-lenis";
import type { NavigationProps } from "@boxt/design-system";

import { getButterComponentsForPage, getHeroForPage } from "@Helpers/renderButterComponents";
import { sendToCs } from "@Lib/contentsquare";
import ErrorHandler from "@Components/ErrorHandler";
import { heroProductWidgetContent } from "@Components/HeroProductWidget/content";
import { HeroProductWidget } from "@Components/HeroProductWidget/HeroProductWidget";
import type { ButterLandingHeroFields } from "@Components/pages/common/ButterLandingHero/types";
import ButterPropositionBanner from "@Components/pages/common/ButterPropositionBanner";
import type { ButterPropositionBannerFields } from "@Components/pages/common/ButterPropositionBanner/types";
import Loader from "@Components/pages/common/Loader";
import ContentPageComponent from "@Components/pages/content/components/ContentPage";
import { StyledPage } from "@Components/pages/content/styles";
import type { ButterContentPageFields } from "@Components/pages/content/types";
import PromoCodeToast from "@Components/PromoCodeToast/PromoCodeToast";
import useChat from "@Hooks/useChat";
import useCleanCookie from "@Hooks/useCleanCookie";
import usePromoCode from "@Hooks/usePromoCode";
import { useSetFilterCookie } from "@Hooks/useSetFilterCookie/useSetFilterCookie";
import { screenerProductType } from "@Pages/api/_next/screeners/_constants";
import type { ScreenerVariant } from "@Pages/api/_next/screeners/types";

const Navigation = dynamic<NavigationProps>(() => import("@boxt/design-system").then((library) => library.Navigation), {
  ssr: false,
});

export type Props = {
  fields?: ButterContentPageFields;
  slug?: string;
  destination: string;
  hasFlagshipLoaded: boolean;
  redirectOnClient?: boolean;
};

const PreviewPage = ({ fields, slug, destination, hasFlagshipLoaded, redirectOnClient }: Props) => {
  const { push, isFallback, query, isReady } = useRouter();
  const { openChat, hide, minimize } = useChat();

  const screeningVariant: ScreenerVariant | undefined = (
    fields?.modules?.find(({ type }) => type === "landing_hero")?.fields as ButterLandingHeroFields
  )?.product_screening_variant?.slug;
  const productType = screeningVariant ? screenerProductType[screeningVariant] : undefined;

  const isFeatureFlagActive = useFsFlag(fields?.flagship_flag as string, false).getValue();
  const isPolicyIntegrationFlagActive = useFsFlag("policy-integration", false).getValue();
  const isBlpRedirectEnabled = useFsFlag("blp_full_page_reformat", false).getValue();

  const isHomepage = slug === "home-page";

  const cardsRef: RefObject<HTMLDivElement> | null = useRef(null);
  const contentPageRef = useRef<HTMLDivElement>(null);

  const { hasCleanParam, cleanAll, removeCleanDataQueryParam } = useCleanCookie();
  const {
    hasActivePromoCode,
    isPromoCodeAllowedForProductType,
    promoCodeData,
    promoCodeError,
    removePromoCodeQueryParam,
  } = usePromoCode(null, productType, screeningVariant);

  useSetFilterCookie();

  useEffect(() => {
    if (slug === "cover") {
      sendToCs("AB_ABT_FS_policy-integration", "New variation");
    }
  }, [slug]);

  useEffect(() => {
    if (slug === "boilers-ab" && isBlpRedirectEnabled) {
      sendToCs("AB_ABT_FS_blp_full_page_reformat", "New variation");
    } else if (slug === "boiler" && isBlpRedirectEnabled) {
      sendToCs("AB_ABT_FS_blp_full_page_reformat", "Original");
    }
  }, [isBlpRedirectEnabled, slug]);

  useEffect(() => {
    const cleanData = async () => {
      await cleanAll();
    };
    if (hasCleanParam) {
      cleanData();
      removeCleanDataQueryParam();
    }
  }, [cleanAll, hasCleanParam, removeCleanDataQueryParam, slug]);

  const shouldRedirect =
    redirectOnClient ||
    (hasFlagshipLoaded &&
      (fields?.flagship_flag ? !isFeatureFlagActive && isReady && query?.[fields.flagship_flag] !== "true" : false));

  const [isSmoothScrollingEnabled, setIsSmoothScrollingEnabled] = useState(fields?.smooth_scrolling);

  useEffect(() => {
    if (shouldRedirect) {
      push(destination);
    }
  }, [shouldRedirect, destination, push]);

  const renderedHero = getHeroForPage(fields?.modules, slug, cardsRef, contentPageRef);

  const renderedModules = getButterComponentsForPage(fields?.modules, slug, cardsRef);

  const toggleSmoothScrolling = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        setIsSmoothScrollingEnabled(false);
      } else {
        if (fields?.smooth_scrolling) {
          setIsSmoothScrollingEnabled(true);
        }
      }
    },
    [fields?.smooth_scrolling],
  );

  const isLoading = isFallback || shouldRedirect;

  const pageContent = (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <ContentPageComponent
          contentPageRef={contentPageRef}
          renderedHero={renderedHero}
          renderedModules={renderedModules}
        />
      )}
    </>
  );

  const propositionBanner = fields?.modules?.find(({ type }) => {
    return type === "proposition_banner";
  });

  const getCorrectUrl = (slug?: string) => {
    const baseUrl = getConfig().publicRuntimeConfig.CUSTOMER_SITE_URL;
    switch (slug) {
      case "boilers":
      case "boilers-ab":
        return `${baseUrl}/boilers`;
      case "cover":
        return `${baseUrl}/cover`;
      case "solar":
        return `${baseUrl}/solar`;
      default:
        return baseUrl;
    }
  };

  const canonicalUrl = getCorrectUrl(slug);
  const hasCanonicalLink = slug && ["solar", "boilers-ab", "boilers", "home-page", "cover"].includes(slug);

  return (
    <StyledPage>
      <Head>
        <title>{fields?.page_title}</title>
        <meta content={fields?.page_description} name="description" />
        {fields?.no_index && <meta content="noindex, follow" name="robots" />}
        {hasCanonicalLink && <link href={canonicalUrl} rel="canonical" />}
      </Head>
      <>
        <Navigation
          hideLiveChat={hide}
          isPolicyIntegrationFlagActive={isPolicyIntegrationFlagActive}
          minimizeLiveChat={minimize}
          onOpenToggle={toggleSmoothScrolling}
          openLiveChat={openChat}
        >
          {propositionBanner && (
            <ButterPropositionBanner fields={propositionBanner.fields as ButterPropositionBannerFields} />
          )}
        </Navigation>
        {isSmoothScrollingEnabled ? (
          <ReactLenis options={{ duration: 1.3 }} root>
            {pageContent}
          </ReactLenis>
        ) : (
          pageContent
        )}
        {isHomepage ? (
          <HeroProductWidget
            content={heroProductWidgetContent}
            i18nNamespace="common"
            renderDelay={1000}
            testId="homepage-hero-product-widget"
          />
        ) : null}
        {isPromoCodeAllowedForProductType && hasActivePromoCode ? <PromoCodeToast promoCode={promoCodeData} /> : null}
        {Boolean(promoCodeError) && <ErrorHandler errorKey={promoCodeError} onDismiss={removePromoCodeQueryParam} />}
      </>
    </StyledPage>
  );
};

export default PreviewPage;
