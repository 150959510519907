import { BOILER_SUBSCRIPTION_URLS, CUSTOMER_URLS, ORDER_URLS, SOLAR_URLS } from "@Collections/routes";

import AC from "./assets/air-conditioning.png";
import BatteryOnly from "./assets/battery-only.png";
import Boilers from "./assets/boilers.png";
import BoilerPlan from "./assets/boxt-life.png";
import HeatPumps from "./assets/heat-pumps.png";
import SolarBattery from "./assets/solar.png";
import type { Card } from "./types";

export const heroProductWidgetContent: Card[] = [
  {
    href: CUSTOMER_URLS.BOILER_SCREENING,
    image: Boilers,
    title: "Boilers",
  },
  {
    href: BOILER_SUBSCRIPTION_URLS.LANDING,
    image: BoilerPlan,
    title: "BOXT Life",
  },
  {
    href: ORDER_URLS.heat_pump.SCREENING,
    image: HeatPumps,
    title: "Heat pumps",
  },
  {
    href: SOLAR_URLS.SCREENING,
    image: SolarBattery,
    title: "Solar & battery",
  },
  {
    href: ORDER_URLS.battery_only.LANDING,
    image: BatteryOnly,
    title: "Battery storage",
  },
  {
    href: ORDER_URLS.air_con.SCREENING,
    image: AC,
    title: "Air conditioning",
  },
];
