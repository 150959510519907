import { useRouter } from "next/compat/router";
import Trans from "next-translate/Trans";
import { Button } from "@boxt/design-system";

import { REPAIR_URLS } from "@Collections/routes";

export type ButtonType = "dismiss" | "reload" | "home";
export type ButtonThemeType = "jade" | "coral";

type ButtonProps = {
  onClick?: () => void;
  nameSpace: { path: string; key: string };
  type?: ButtonType;
  homePath?: string;
  buttonTheme?: ButtonThemeType;
};

const PopUpButton = ({
  buttonTheme = "jade",
  onClick,
  type,
  nameSpace: { path, key },
  homePath = REPAIR_URLS.LANDING,
}: ButtonProps) => {
  const router = useRouter();

  const handleClick = () => {
    onClick?.();

    if (type === "reload") router?.reload();
    if (type === "home") router?.push(homePath);
  };

  return (
    <Button boxtTheme={buttonTheme} isFullWidth onClick={handleClick}>
      <Trans i18nKey={`${path}:errors.${key}.button`} />
    </Button>
  );
};

export default PopUpButton;
